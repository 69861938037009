@import url("https://fonts.googleapis.com/css2?family=Reem+Kufi+Ink:wght@700&family=Inter:wght@400;600&display=swap");

.specializationSection {
  background-color: #fffcf3;
  padding: 4rem 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.header {
  font-family: "Reem Kufi Ink", sans-serif;
  font-weight: 700;
  color: #0d4448;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.animateHeader {
  opacity: 1;
  transform: translateY(0);
}

.specializationList {
  opacity: 0;
  transition: opacity 0.5s ease;
  list-style-type: none;
  padding: 0;
}

.animateMenu {
  opacity: 1;
  transition-delay: 0.3s;
}

.specializationItem {
  margin-bottom: 1rem;
  border-bottom: 1px solid #0d4448;
}

.specializationQuestion {
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  color: #0d4448;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  padding: 1rem 0;
}

.plus::after,
.minus::after {
  content: "+";
  font-size: 1.5rem;
  line-height: 1;
  color: #0d4448;
}

.minus::after {
  content: "-";
}

.specializationAnswer {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: #0d4448;
  padding: 1rem 0;
  line-height: 1.6;
  animation: fadeSlideDown 0.3s ease-out;
}

@keyframes fadeSlideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
