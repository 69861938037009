.button {
  background-color: #8b572a; /* Adjust as needed */
  color: white;
  font-family: "Inter", sans-serif;
  padding: 12px 24px; /* Increased padding */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in;
  text-decoration: none;
}

.button:hover {
  background-color: #6d451e; /* Adjust as needed */
  text-decoration: none;
}
