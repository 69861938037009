/* Import font */
@import url("https://fonts.googleapis.com/css2?family=Jomolhari&display=swap");

.navBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #fffcf3;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.logoContainer {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #4b2f04;
}

.logo {
  width: 75px;
  height: 75px;
  margin-right: 10px;
}

.siteNameContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.siteName {
  font-family: "Jomolhari", serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: #4b2f04;
  text-align: center;
}

.separator {
  width: 100%;
  border: 0;
  border-top: 1px solid #4b2f04;
  margin: 0.2rem 0;
}

.siteSubName {
  font-family: "Jomolhari", serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: #4b2f04;
  text-align: center;
}

.desktopNav {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.navLinks {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navLink {
  font-family: "Jomolhari", serif;
  text-decoration: none;
  color: #4b2f04;
  margin: 0 1rem;
}

.appointmentButton {
  font-family: "Inter", sans-serif;
  background-color: #8b572a;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in;
  margin-left: 1rem;
  text-decoration: none;
}

.appointmentButton:hover {
  background-color: #6d451e;
  text-decoration: none;
}

.mobileNav {
  display: none;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger span {
  width: 25px;
  height: 3px;
  background-color: #4b2f04;
  margin: 2px 0;
  transition: 0.4s;
}

@media (max-width: 768px) {
  .desktopNav {
    display: none;
  }

  .mobileNav {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fffcf3;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }

  .mobileNav.open {
    display: flex;
  }

  .mobileNav .navLinks {
    flex-direction: column;
    align-items: center;
  }

  .mobileNav .navLink {
    margin: 0.5rem 0;
  }

  .mobileNav .appointmentButton {
    margin-top: 1rem;
    margin-left: 0;
  }

  .hamburger {
    display: flex;
  }

  .navBar .appointmentButton {
    display: none;
  }
}
