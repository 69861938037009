@import url("https://fonts.googleapis.com/css2?family=Jomolhari&family=Inter:wght@400;600&display=swap");

.footer {
  background-color: #0d4448;
  color: white;
  padding: 3rem 0 1rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.logoContainer {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  cursor: pointer;
}

.logo {
  width: 50px;
  height: auto;
  margin-right: 1rem;
}

.siteNameContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.siteName,
.siteSubName {
  font-family: "Jomolhari", serif;
  font-size: 1.2rem;
  line-height: 1.2;
}

.separator {
  width: 100%;
  border: none;
  border-top: 1px solid white;
  margin: 4px 0;
}

.linksSection {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  margin-left: 2rem;
}

.linkColumn {
  margin-right: 2rem;
}

.linkHeader {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.link,
.address {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  display: block;
  margin-bottom: 0.5rem;
  color: white;
  text-decoration: none;
  font-size: 0.9rem;
  transition: opacity 0.3s ease;
}

.link:hover {
  opacity: 0.8;
}

.copyright {
  text-align: center;
  margin-top: 2rem;
  font-family: "Inter", sans-serif;
  font-size: 0.8rem;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .linksSection {
    flex-direction: column;
    margin-left: 0;
  }

  .linkColumn {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
