@import url("https://fonts.googleapis.com/css2?family=Reem+Kufi+Ink:wght@400;700&family=Inter:wght@400&display=swap");

.section {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.heroImage {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translate(0, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 400px;
  opacity: 0;
  transition: opacity 0.7s ease, transform 0.7s ease;
}

.modal.hidden {
  opacity: 0;
  transform: translate(100%, -50%);
}

.modal.visible {
  opacity: 1;
  transform: translate(0, -50%);
}

.heading {
  font-family: "Reem Kufi Ink", sans-serif;
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1rem;
}

.description {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #666;
  margin-bottom: 1.5rem;
}

.button {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  background-color: #4b2f04;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.button:hover {
  background-color: #3a2503;
}
