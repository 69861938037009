@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR&display=swap");

.hero {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  opacity: 0;
  transition: opacity 1s ease-in;
}

.heroImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  object-position: center;
}

/* Media query for small screens */
@media (max-width: 768px) {
  .heroImage {
    object-position: center;
  }
}

.heroMask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

.heroContent {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  color: white;
  font-family: "IBM Plex Sans KR", sans-serif;
  text-align: left;
  padding: 2rem;
  margin-left: 5%;
}

.heroContent h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.hidden {
  opacity: 0;
}

.visible {
  opacity: 1;
  transition: opacity 1s ease-in;
}
