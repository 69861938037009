@import url("https://fonts.googleapis.com/css2?family=Reem+Kufi+Ink:wght@400;700&family=Inter:wght@400&display=swap");

.sectionWrapper {
  background-color: #fffef8;
  width: 100%;
  padding: 4rem 0;
}

.section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  overflow: hidden;
}

.heading {
  font-family: "Reem Kufi Ink", sans-serif;
  font-size: 3.5rem;
  color: #0d4448;
  text-align: center;
  margin-bottom: 1rem;
  opacity: 0;
  transform: translateY(50px);
}

.subtext {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  color: #0d4448;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 3rem;
  opacity: 0;
  transform: translateY(50px);
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.box {
  background-color: #f5f5f5;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  opacity: 0;
}

.icon {
  width: 50px;
  height: 50px;
  margin-bottom: 1rem;
}

.boxTitle {
  font-family: "Reem Kufi Ink", sans-serif;
  font-size: 1.2rem;
  color: #0d4448;
  margin-bottom: 1rem;
}

.boxDescription {
  font-family: "Inter", sans-serif;
  font-size: 0.9rem;
  color: #0d4448;
  margin-bottom: 1rem;
}

.button {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #0d4448;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid #0d4448;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/* Animations */
.animateHeading {
  animation: fadeSlideUp 0.8s ease-out forwards;
}

.animateSubtext {
  animation: fadeSlideUp 0.8s ease-out 0.2s forwards;
}

.animateBox {
  animation: fadeIn 2.5s ease-out forwards;
}

@keyframes fadeSlideUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Media Queries */
@media (max-width: 1024px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }

  .heading {
    font-size: 2rem;
  }

  .subtext {
    font-size: 0.9rem;
  }
}
