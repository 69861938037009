@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");

.card {
  background-color: #cec7bc;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.7s ease, transform 0.9s ease;
}

.card.visible {
  opacity: 1;
  transform: translateY(0);
}

.imageContainer {
  height: 200px;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.title {
  font-family: "Reem Kufi Ink", sans-serif;
  color: black;
  font-size: 1rem;
  margin-bottom: 1rem;
  text-align: left;
  padding-bottom: 40px;
}

.button {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #000;
  background-color: transparent;
  border: 1px solid #000;
  padding: 0.5rem 1rem;
  cursor: pointer;
  align-self: end;
  transition: background-color 0.3s ease;
  border-radius: 8px;
  text-decoration: none;
}

.button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (max-width: 480px) {
  .imageContainer {
    height: 150px; /* Further reduce image height */
  }

  .title {
    font-size: 1.2rem;
  }

  .button {
    font-size: 0.8rem;
    text-decoration: none;
  }
}
