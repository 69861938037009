@import url("https://fonts.googleapis.com/css2?family=Reem+Kufi+Ink:wght@700&family=Inter:wght@400;600&display=swap");

.section {
  background-color: #f5f5f5;
  padding: 4rem 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.header {
  font-family: "Reem Kufi Ink", sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
  color: #0d4448;
  text-align: center;
  margin-bottom: 2rem;
}

.boxesContainer {
  background-color: #0d4448;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
}

.box {
  flex: 1;
  padding: 1.5rem;
  color: white;
  opacity: 0;
  transition: opacity 3s ease;
}

.box:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.fadeIn {
  opacity: 1;
}

.boxTitle {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.boxContent {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .boxesContainer {
    flex-direction: column;
  }

  .box {
    padding: 1.5rem 0;
  }

  .box:not(:last-child) {
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
}
