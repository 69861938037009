@import url("https://fonts.googleapis.com/css2?family=Reem+Kufi+Ink:wght@700&family=Inter:wght@400&display=swap");

.bottomHero {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.heroImage {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  opacity: 0;
  transition: opacity 2s ease;
}

.animate {
  opacity: 1;
}

.header {
  font-family: "Reem Kufi Ink", sans-serif;
  font-size: 3rem;
  margin-bottom: 1rem;
}

.subtext {
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

@media (max-width: 768px) {
  .header {
    font-size: 2rem;
  }

  .subtext {
    font-size: 1rem;
  }

  .buttonContainer {
    flex-direction: column;
    align-items: center;
  }
}
